import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

// Hooks & Querys & Context
import { QueryFragments } from '../GraphQl/queryFragments' // eslint-disable-line
import { useSiteState } from '../lib/navContext'

// Components
import ModuleZone from '~moduleZone'
import { PageMeta } from '../components/pageMeta'
import Layout from '../components/layout'
import { PageHeader } from '../components/pageModules/modules'

import { Helmet } from 'react-helmet'

const PageTemplate = ({ data: { page } }) => {
  const { homeHeader, setHomeHeader } = useSiteState()

  const { pageHeading, pageModules, config } = page || {}
  const { bgColor } = config || {}

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false)
    }
    if (bgColor?.title === 'White') {
      document.documentElement.style.setProperty(`--docBg`, `#EAE9E7`)
      document.documentElement.style.setProperty(`--docBgAlt`, `#fff`)
      document.documentElement.style.setProperty(`--docText`, `#000`)
      document.documentElement.style.setProperty(`--docNavActive`, `#A7A9AB`)
    } else if (bgColor?.title === 'Green') {
      document.documentElement.style.setProperty(`--docBg`, `${bgColor.value}`)
      document.documentElement.style.setProperty(`--docBgAlt`, `#E0F4E3`)
      document.documentElement.style.setProperty(`--docText`, `#fff`)
      document.documentElement.style.setProperty(`--docNavActive`, `#fff`)
    } else {
      document.documentElement.style.setProperty(`--docBg`, `${bgColor.value}`)
      document.documentElement.style.setProperty(`--docBgAlt`, `#222`)
      document.documentElement.style.setProperty(`--docText`, `#fff`)
      document.documentElement.style.setProperty(`--docNavActive`, `#AFE5BA`)
    }
  }, [bgColor, setHomeHeader, homeHeader])

  return (
    <Layout>
      {page.seo && <PageMeta {...page.seo} />}
      {pageHeading && <PageHeader title={pageHeading} />}
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      title
      pageHeading
      config {
        ...PageSettings
      }
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`
